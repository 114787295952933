import React, { useState, useEffect, useCallback } from 'react';
import { Button, Badge } from 'react-bootstrap';
import { GET, PUT, extractData } from '../../Consumer';
import LoadingBar from '../common/LoadingBar';
import Endpoints from '../common/Endpoints';
import { GridErrorAlert, GridInfoAlert } from '../common/Alerts';
import { CollectionManifestTableHeaders } from '../common/Constants';
import { toLocalTimeString } from '../../Utilities';
import PagingTable from '../common/tables/PagingTable';

const CollectionManifests = ({ currentlySelectedWarehouse }) => {

    //#region State

    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    const [pageIndex, setPageIndex] = useState(1);
    const [pageCount, setPageCount] = useState(1);

    const [rows, setRows] = useState([]);

    const [renderCount, setRenderCount] = useState(0);

    //#endregion

    //#region Actions

    const markPrinted = useCallback(async (id) => {
        try {
            const url = new URL(currentlySelectedWarehouse
                ? Endpoints.FULFILMENT.PUT.MANIFEST + id + "/" + currentlySelectedWarehouse
                : Endpoints.FULFILMENT.PUT.MANIFEST + id);

            const response = await PUT(url);
            if (response?.ok) {
                const responseData = await response.json();
                if (responseData) {
                   setRenderCount(currentCount => currentCount + 1);
                }
                setShowError(false);
            }
            else {
                setShowError(true);
            }
        } catch (error) {
            console.log(error);
            setShowError(true);
        } 
    }, [currentlySelectedWarehouse]);

    const download = useCallback(async (id) => {
        setLoading(true);
        try {
            const url = new URL(currentlySelectedWarehouse
                ? Endpoints.FULFILMENT.GET.MANIFEST + id + "/" + currentlySelectedWarehouse
                : Endpoints.FULFILMENT.GET.MANIFEST + id);

            const response = await GET(url);
            if (response?.ok) {
                const manifestBlob = await response.blob();
                const objectURL = URL.createObjectURL(manifestBlob);
                window.open(objectURL);
                await markPrinted(id);
                setShowError(false);
            }
            else {
                setShowError(true);
            }
        } catch (e) {
            console.log(e);
            setShowError(false);
        } finally {
            setLoading(false);
        }
    }, [currentlySelectedWarehouse, markPrinted]);

    //#endregion

    //#region API

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const url = new URL(currentlySelectedWarehouse
                    ? Endpoints.FULFILMENT.GET.MANIFESTS + "/" + currentlySelectedWarehouse
                    : Endpoints.FULFILMENT.GET.MANIFESTS);
                url.searchParams.append("pageIndex", pageIndex);

                const response = await GET(url);
                if (response?.ok) {
                    const responseData = await response.json();
                    const { manifests, pageCount } = extractData(responseData);
                    const manifestRows = [];

                    manifests.forEach(manifest => manifestRows.push([
                        <div>
                            {(manifest.courierID === '00000000-0000-0000-0000-000000000005')
                                ? "Royal Mail"
                                : ""}
                        </div>,
                        toLocalTimeString(manifest.createdDate),
                        <div>
                            {manifest.printed
                                ? <Badge className="green">Printed</Badge>
                                : <Badge className="grey">Not Printed</Badge>}
                        </div>,
                        <div>
                            {(manifest.printedBy !== null)
                                ? manifest.printedBy
                                : ""}
                        </div>,
                        <div>
                            {manifest.url && <div className="my-2"><Button variant="secondary"
                                onClick={() => download(manifest.id)}>Print Manifest</Button></div>}
                        </div>
                    ]));

                    setRows(manifestRows);
                    setPageCount(pageCount);
                } else {
                    setShowError(true);
                }
            } catch (error) {
                console.log({ error });
                setShowError(true);
            } finally {
                setLoading(false);
            }
        })();
    }, [currentlySelectedWarehouse, pageIndex, download, renderCount]);

    //#endregion

    //#region Controls

    const onNext = () => {
        if (pageIndex < pageCount) setPageIndex((index) => index + 1);
    };

    const onPrevious = () => {
        if (pageIndex > 1) setPageIndex((index) => index - 1);
    };

    const onStart = () => setPageIndex(1);
    const onEnd = () => setPageIndex(pageCount);

    //#endregion

    return (
            loading
            ? <LoadingBar />
            : <div className="center-block mt-5">
                <h1 className="mb-3">Collections</h1>
                {showError
                    ? <GridErrorAlert message="Could not retrieve collection manifests for this warehouse." />
                    : <React.Fragment>
                        {rows.length === 0 && <GridInfoAlert message="There are no collection manifests at this time." />}
                            <PagingTable
                                headers={CollectionManifestTableHeaders}
                                rows={rows}
                                onNext={onNext}
                                onPrevious={onPrevious}
                                onStart={onStart}
                                onEnd={onEnd}
                                pageIndex={pageIndex}
                                pageCount={pageCount} />
                    </React.Fragment>
                }
            </div>
        );
}

export default CollectionManifests;